<!-- src/components/QuizCreator.vue -->
<template>
  <div class="quiz-creator">
    <h1>Create a New Quiz</h1>
    <!-- Quiz Name Input -->
    <div class="quiz-name">
      <label for="quiz-name">Quiz Name:</label>
      <input
        id="quiz-name"
        v-model="quizName"
        placeholder="Enter quiz name"
      />
    </div>

    <!-- Questions Section -->
    <div class="questions">
      <h2>Questions</h2>
      <div
        class="question"
        v-for="(question, index) in questions"
        :key="index"
      >
        <h3>Question {{ index + 1 }}</h3>
        <!-- Question Text -->
        <div>
          <label>Question Text:</label>
          <input
            v-model="question.text"
            placeholder="Enter question text"
          />
        </div>
        <!-- Answer 1 -->
        <div>
          <label>Answer 1:</label>
          <input
            v-model="question.answer1"
            placeholder="Enter first answer"
          />
        </div>
        <!-- Answer 2 (Optional) -->
        <div v-if="question.hasSecondAnswer">
          <label>Answer 2:</label>
          <input
            v-model="question.answer2"
            placeholder="Enter second answer"
          />
        </div>
        <!-- Toggle Second Answer Button -->
        <button @click="toggleSecondAnswer(index)">
          {{ question.hasSecondAnswer ? 'Remove' : 'Add' }} Second Answer
        </button>
        <!-- Remove Question Button -->
        <button @click="removeQuestion(index)">Remove Question</button>
        <hr />
      </div>
      <!-- Add New Question Button -->
      <button @click="addQuestion">Add New Question</button>
    </div>

    <!-- Save Quiz Button -->
    <div class="save-quiz">
      <button @click="saveQuiz">Save Quiz</button>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { get_token,backend_post, backend_upload } from '@/services/cognito-auth';
import { server } from '@/constants.js';

export default {
  name: 'QuizCreator',
  setup() {
    // Reactive Variables
    const quizName = ref('');
    const questions = ref([]);

    // Add a New Question
    const addQuestion = () => {
      questions.value.push({
        text: '',
        answer1: '',
        answer2: '',
        hasSecondAnswer: false,
      });
    };

    // Remove a Question
    const removeQuestion = (index) => {
      questions.value.splice(index, 1);
    };

    // Toggle Second Answer Input
    const toggleSecondAnswer = (index) => {
      const question = questions.value[index];
      question.hasSecondAnswer = !question.hasSecondAnswer;
      if (!question.hasSecondAnswer) {
        question.answer2 = '';
      }
    };

    // Save the Quiz
    const saveQuiz = async () => {
      const quizData = {
        name: quizName.value,
        questions: questions.value.map((q) => ({
          question: q.text,
          answers: [q.answer1, q.answer2].filter(Boolean),
        })),
      };
      console.log('Quiz Data:', quizData);
      
      // You can send 'quizData' to your server or handle it as needed


	  let quiz = {name: quizData.name,
		      questions: quizData.questions}

	  console.log(quiz)


	  let r = await backend_post(`${server}/quiz/new`,quiz)
	  console.log(r)


    };

    return {
      quizName,
      questions,
      addQuestion,
      removeQuestion,
      toggleSecondAnswer,
      saveQuiz,
    };
  },
};
</script>

<style scoped>
.quiz-creator {
  max-width: 600px;
  margin: auto;
  padding: 20px;
}

.quiz-name,
.question {
  margin-bottom: 20px;
}

label {
  display: block;
  margin: 10px 0 5px;
}

input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

button {
  margin-top: 10px;
  padding: 8px 12px;
}

hr {
  margin: 20px 0;
}

.save-quiz {
  text-align: center;
}
</style>
