<!-- src/components/QuizCreator.vue -->
<template>
  <div class="quiz-creator">
    <h1>Edit Quiz</h1>
    <!-- Quiz Name Input -->
    <div class="quiz-name">
      <label for="quiz-name">Quiz Name:</label>
      <input
        id="quiz-name"
        v-model="quizName"
        placeholder="Enter quiz name"
      />
    </div>

    <!-- Questions Section -->
    <div class="questions">
      <h2>Questions</h2>
      <div
        class="question"
        v-for="(question, index) in questions"
        :key="question.id || index"
      >
        <h3>Question {{ index + 1 }}</h3>
        <!-- Question Text -->
        <div>
          <label>Question Text:</label>
          <input
            v-model="question.text"
            placeholder="Enter question text"
          />
    </div>
        <div>
          <label>Explanation Text:</label>
          <input
            v-model="question.e"
            placeholder="Enter explaination text"
          />
        </div>
    
        <!-- Answer 1 -->
        <div>
          <label>Answer 1:</label>
          <input
            v-model="question.answers[0]"
            placeholder="Enter first answer"
          />
        </div>
        <!-- Answer 2 (Optional) -->
        <div v-if="question.hasSecondAnswer">
          <label>Answer 2:</label>
          <input
            v-model="question.answers[1]"
            placeholder="Enter second answer"
          />
        </div>
        <!-- Toggle Second Answer Button -->
        <button @click="toggleSecondAnswer(index)">
          {{ question.hasSecondAnswer ? 'Remove' : 'Add' }} Second Answer
        </button>
        <!-- Remove Question Button -->
        <button @click="removeQuestion(index)">Remove Question</button>
        <hr />
      </div>
      <!-- Add New Question Button -->
      <button @click="addQuestion">Add New Question</button>
    </div>

    <!-- Save Quiz Button -->
    <div class="save-quiz">
      <button @click="saveQuiz">Save Quiz</button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { get_token,backend_post, backend_get} from '@/services/cognito-auth';
import { server } from '@/constants.js';

export default {
    name: 'QuizCreator',
    props: {
	quizId: {
	    type: Number, // or Number, depending on your use case
	    required: true
	}
    },
  data() {
      return {
	  quizName: '',
	  questions: []
      }
  },
  async mounted() {
    // Reactive Variables
    const quizName = ref('');
    const questions = ref([]);

	let url = `${server}/quiz/get/${this.quizId}`
	let r  = await backend_get(url)
      console.log(r.data.quiz)
      let quizData = r.data.quiz
      this.quizName = quizData.name
      //this.questions = quizData.questions
      this.questions = quizData.questions.map((q) => ({
          id: q.id,
          text: q.question,
          answers: q.answers,
          hasSecondAnswer: q.answers.length > 1,
        }));      
  
  },
 methods: {
    // Add a New Question
    addQuestion()  {
      this.questions.push({
        id: Date.now(), // Generate a unique ID
        text: '',
        answers: [''],
        hasSecondAnswer: false,
      });
    },

    // Remove a Question
    removeQuestion(index) {
      questions.value.splice(index, 1);
    },

    // Toggle Second Answer Input
     toggleSecondAnswer(index) {
      const question = this.questions[index];
      question.hasSecondAnswer = !question.hasSecondAnswer;
      if (question.hasSecondAnswer) {
        // Add a second answer slot
        question.answers[1] = '';
      } else {
        // Remove the second answer
        question.answers.splice(1, 1);
      }
     },

    // Save the Quiz
      async saveQuiz() {

      const quizData = {
        name: this.quizName,
        questions: this.questions.map((q) => ({
          question: q.text,
          answers: q.answers//[q.answer1, q.answer2].filter(Boolean),
        })),
      };
      console.log('Quiz Data:', quizData);
      
      // You can send 'quizData' to your server or handle it as needed


	  let quiz = {name: quizData.name,
		      questions: quizData.questions}

	  console.log(quiz)

	  let r = await backend_post(`${server}/quiz/edit/${this.quizId}`,quiz)
	  
	  console.log(r)
	  
	  /*
      const quizData = {
        name: quizName.value,
        questions: questions.value.map((q) => ({
          id: q.id,
          text: q.text,
          answers: q.answers.filter(Boolean),
        })),
      };
      console.log('Quiz Data to Save:', quizData);
      // Stubbed API call to save data
      // Replace this with your actual API call
      alert('Quiz saved successfully!');
      */
      }

 }


};
</script>

<style scoped>
.quiz-creator {
  max-width: 600px;
  margin: auto;
  padding: 20px;
}

.quiz-name,
.question {
  margin-bottom: 20px;
}

label {
  display: block;
  margin: 10px 0 5px;
}

input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

button {
  margin-top: 10px;
  padding: 8px 12px;
}

hr {
  margin: 20px 0;
}

.save-quiz {
  text-align: center;
}
</style>
